@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
  --accent-color: #26BCBC;
  --primary-font-family: Roboto;
  --primary-font-family-bold: Roboto;
  --secondary-color: #DFA100;
  --link-text-color: #04518d;
  --fullhd-res: 1400px;
  --sxga-res: 1280px;
  --hd-res: 1200px;
}

@font-face {
  font-family: Roboto;
  font-weight: 200;
  src: url(assets/fonts/roboto/Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url(assets/fonts/roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url(assets/fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url(assets/fonts/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 600;
  src: url(assets/fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  src: url(assets/fonts/bebas/BebasNeue-Regular.ttf);
}

html {
  height: 100%;
}

body {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-size: 13px;
  margin: 0;
  height: 100%;
}

.ns-spinner-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

::ng-deep .real-property-details {
  max-width: 100%;
  max-height: 100%;
}

.real-property-details {
  max-width: 100%;
  max-height: 100%;
}

button {
  cursor: pointer;
}

.font-cyr {
  font-family: var(--primary-font-family);
}

.orange-border{
  color: #F28F17 !important;
  border: 1px solid #F28F17;
}

.gray-border {
  border: 0.5px solid #CDCDCD;
  border-radius: 25px;
}

.full-height{
  height: 100%;
}

.p-center {
  justify-content: center;
  align-items: center;
}

.p-not-margin{
  margin: 0 !important;
}
.p-not-margin-left{
  margin-left: 0 !important;;
}
.p-not-margin-right{
  margin-right: 0 !important;;
}
.p-not-margin-top{
  margin-top: 0 !important;;
}
.p-not-margin-bottom{
  margin-bottom: 0 !important;;
}
.p-not-padding{
  padding: 0 !important;;
}
.p-not-padding-left{
  padding-left: 0 !important;;
}
.p-not-padding-top{
  padding-top: 0 !important;
}
.p-not-padding-right{
  padding-right: 0 !important;;
}
.p-not-padding-bottom{
  padding-bottom: 0 !important;;
}

.p-accordion-header .p-accordion-header-link {
  border-radius: 15px !important;
}

.p-avatar img {
  object-fit: cover;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 1366px) {
  .static-width {
    width: var(--sxga-res) !important;
  }
}

@media screen and (max-width: 1280px) {
  .static-width {
    min-width: var(--hd-res) !important;
    width: var(--hd-res) !important;
  }
}
